<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="模板名称">
              <el-input v-model="searchForm.contractName" placeholder="请输入要搜索的模板名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属资金方">
              <el-input v-model="searchForm.capName" placeholder="请输入要搜索的所属资金方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审批状态">
              <el-select v-model="searchForm.approvalStatus" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.contractApprovalStatus" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建/变更日期">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="$router.push({ name: 'templateEdit', params: { editMode: 'add' }})">
            新增模板
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column label="序号" type="index" :index="indexMethod" />
          <el-table-column prop="template.contractTemplateNumber" label="模板编号" min-width="140" :formatter="utils.isEffective" />
          <el-table-column prop="template.contractName" label="模板名称" min-width="140" :formatter="utils.isEffective" />
          <el-table-column label="模板文件" min-width="230">
            <template v-slot="scope">
              <span v-if="scope.row.template.contractUrl">
                <a href="javascript:" class="text-btn" @click="utils.downloadP('concat', scope.row.template.contractUrl)">
                  {{ utils.isEffectiveCommon(scope.row.template.contractUrlName) }}
                </a>
              </span>
              <span v-else>
                -
              </span>
            </template>
          </el-table-column>
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="160">
            <template v-slot="scope">
              <ics-auth-manage code="合同模板">
                <el-link v-if="[0, 3].includes(scope.row.template.approvalStatus)" type="primary" style="margin-left: 10px;" class="text-btn" @click="dialogAudit(scope.row.template.id)">
                  审批
                </el-link>
              </ics-auth-manage>
              <el-link v-if="[1, 4, 2, 5].includes(scope.row.template.approvalStatus)" type="primary" class="text-btn" @click="$router.push({ name: 'templateEdit', params: { editMode: 'edit' }, query: { id: scope.row.template.id } })">
                变更
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'templateDetail', query: { id: scope.row.template.id } })">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
    <ics-audit-inner :loading-submit="loadingSubmit" :dialog="dialog.audit" @submit="submitAudit" />
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
import IcsAuthManage from '@/components/auth-manage'
import IcsAuditInner from '@/components/audit-inner'

export default {
  components: { IcsAuthManage, IcsAuditInner },
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        contractName: '',
        capName: '',
        approvalStatus: '',
        afterUpdatedAt: '',
        beforeUpdatedAt: ''
      },
      businessId: '',
      loadingSubmit: {
        submit: false
      },
      dialog: {
        audit: {
          visible: false,
          title: '审批合同模板'
        }
      },
      columnOption: [
        { label: '资金方', prop: 'template.capName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'template.createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建/变更时间', prop: 'template.updatedAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '审批状态', prop: 'template.approvalStatus', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'contractApprovalStatus'), minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('afterUpdatedAt', 'beforeUpdatedAt')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.contract.template.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    dialogAudit (val) {
      this.businessId = val
      this.dialog.audit.visible = true
    },
    submitAudit (formData) {
      return new Promise((resolve, reject) => {
        this.loadingSubmit.submit = true
        let data = formData || {}
        console.log(formData)
        this.api.contract.template.altStatus(this.businessId, data.state, data.reason).then(result => {
          if (result.data.success === true) {
            this.loadingSubmit.submit = false
            this.dialog.audit.visible = false
            this.$message.success('操作成功')
            this.getList()
          } else {
            this.$message.error(result.data.message)
            this.loadingSubmit.submit = false
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loadingSubmit.submit = false
        })
      })
    }
  }
}
</script>

<style>

</style>
